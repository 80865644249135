import React from 'react';
import { CmsData } from '~/models/cms-data.d';
import CmsImage from '~/shared/cms-image/cms-image.component';
import { useFormattedDate } from '~/shared/hooks/date-utils.hooks';

import Heading from '~/shared/heading/heading.component';
import styles from './content-page.module.scss';
import { usePage } from '~/libs/queries/bff';
import { Grid, GridContainer } from '~/shared/grid';

type ContentPageGridProps = {
    grid: CmsData.IGrid;
    pageId: string;
};

export const ContentPageGrid = ({ grid, pageId }: ContentPageGridProps) => {
    return <Grid grid={grid} pageId={pageId} />;
};

const ContentPage = () => {
    const { data: pageContent } = usePage<CmsData.IContentPage>();
    const { grid, title, publicationDate, image, shouldDarkenImage, id } = pageContent || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const formattedDate = useFormattedDate(publicationDate ? new Date(publicationDate) : undefined);

    return (
        <>
            {title && (
                <div className={styles.headerWrapper}>
                    <header className={styles.header}>
                        {formattedDate && <span className={styles.date}>{formattedDate}</span>}
                        <Heading tagName="h1">{title}</Heading>
                    </header>
                </div>
            )}

            {image && (
                <div className={styles.imageWrapper}>
                    <div className={styles.imageBg} />
                    <div className={styles.container}>
                        <CmsImage image={image} darken={shouldDarkenImage} />
                    </div>
                </div>
            )}
            <GridContainer>
                <ContentPageGrid grid={grid} pageId={id as string} />
            </GridContainer>
        </>
    );
};

export default ContentPage;
