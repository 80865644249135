import { useMemo } from 'react';
import useTranslations from './use-translations.hook';

/**
 * @author SBF
 *
 * @description
 * Outputs date in the format: Day. Month, Year
 *
 * @example
 * const formattedDate = useFormattedDate(new Date(), translations);
 */

export interface IMonths {
    month: {
        january: string;
        february: string;
        march: string;
        may: string;
        april: string;
        june: string;
        july: string;
        august: string;
        september: string;
        october: string;
        november: string;
        december: string;
    };
}

export function useFormattedDate(date: Date) {
    const translate = useTranslations();
    const month = translate('date.month', 'month');

    return useMemo(() => {
        if (!date) {
            return;
        }

        const months = Object.values(month);
        return `${date.getDate()}. ${months[date.getMonth()]}, ${date.getFullYear()}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);
}
