import React, { FC } from 'react';
import clsx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import { CmsData } from '~/models/cms-data.d';

import styles from './cms-image.module.scss';

export interface ICmsImageProps {
    image: CmsData.ICmsImage;
    darken?: boolean;
}
const CmsImage: FC<ICmsImageProps> = ({ image, darken = false }) => {
    if (!image?.src) {
        return null;
    }

    const { src, focalPoint, name, width, height } = image;
    const { left = 0.5, top = 0.5 } = focalPoint || { left: 0.5, top: 0.5 };

    return (
        <div className={clsx({ [styles.darken]: darken })}>
            <CdnImage
                src={src}
                alt={name}
                layout="responsive"
                height={height}
                width={width}
                {...(focalPoint && {
                    objectFit: 'cover',
                    objectPosition: `${left * 100}% ${top * 100}`,
                })}
            />
        </div>
    );
};

export default CmsImage;
