import React, { FC, memo, useCallback, MouseEventHandler } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import styles from './raw-html.module.scss';
import { IWithClassName } from '~/models/dev';

interface IRawHtmlProps extends IWithClassName {
    content?: string;
}

const RawHtml: FC<IRawHtmlProps> = ({ className, content }) => {
    const { push } = useRouter();

    const interceptLinkClick = useCallback<MouseEventHandler>((e) => {
        const target = e.target as HTMLAnchorElement;

        if (!target || target.tagName !== 'A') {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (target.host !== location.host) {
            return;
        }

        e.preventDefault();

        const url = target.pathname + target.search + target.hash;
        push(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!content) {
        return null;
    }

    return <div className={clsx(styles.rte, className)} onClick={interceptLinkClick} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default memo(RawHtml);
