/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DynamicPageProps, NextPageWithLayout } from '~/page-types/types';
import { RootLayoutContainer, RootLayoutContentContainer } from '~/shell/layouts/root';
import { ContentPageGrid } from '~/page-types/content-page/content-page.component';
import { GridContainer } from '~/shared/grid';

// @ts-ignore
const grid = {
    name: 'Content grid full',
    sections: [
        {
            grid: '12',
            rows: [
                {
                    name: 'Full',
                    id: '1a7d9b8f-2530-4998-8003-04cccec90440',
                    areas: [
                        {
                            grid: '12',
                            controls: [
                                {
                                    value: {
                                        dtgeContentTypeAlias: 'contentPageTextAndMedia',
                                        value: {
                                            name: 'Text and media',
                                            title: 'Noget gik galt på vores side',
                                            body:
                                                'Vi beklager ulejligheden. ' +
                                                'Vi arbejder på højtryk for at finde, og rette, fejlen så du hurtigt kan komme videre. ' +
                                                'Indtil da, kontakt vores supportteam eller hold dig opdateret via driftsbanneret i toppen af siden.',
                                            // @ts-ignore
                                            buttonLink: [],
                                            buttonColor: {
                                                value: '004586',
                                                label: '004586',
                                                sortOrder: 3,
                                                id: '4',
                                            },
                                            backgroundColor: '',
                                            invertedTextColor: '0',
                                            media: {
                                                id: 2022,
                                                // @ts-ignore
                                                crops: [],
                                                src: 'https://dev-ftz-webcatalog.euwest01.umbraco.io/media/1pdneu5d/image.png?ud=EFOFrljP2Ag',
                                                focalPoint: {
                                                    left: 0.5,
                                                    top: 0.5,
                                                },
                                                name: 'Image 500 page',
                                                extension: 'png',
                                                height: 524,
                                                width: 1353,
                                                ratio: 0.38728750923872873,
                                                type: 'image',
                                            },
                                            mediaFitType: 'contain',
                                            flip: '0',
                                            cmsData: {
                                                name: 'Text and media',
                                                title: 'Noget gik galt på vores side',
                                                body:
                                                    'Vi beklager ulejligheden. ' +
                                                    'Vi arbejder på højtryk for at finde, og rette, fejlen så du hurtigt kan komme videre. ' +
                                                    'Indtil da, kontakt vores supportteam eller hold dig opdateret via driftsbanneret i toppen af siden.',
                                                // @ts-ignore
                                                buttonLink: [],
                                                buttonColor: {
                                                    value: '004586',
                                                    label: '004586',
                                                    sortOrder: 3,
                                                    id: '4',
                                                },
                                                backgroundColor: '',
                                                invertedTextColor: '0',
                                                media: {
                                                    id: 2022,
                                                    // @ts-ignore
                                                    crops: [],
                                                    src: 'https://dev-ftz-webcatalog.euwest01.umbraco.io/media/1pdneu5d/image.png?ud=EFOFrljP2Ag',
                                                    focalPoint: {
                                                        left: 0.5,
                                                        top: 0.5,
                                                    },
                                                    name: 'Image 500 page',
                                                    extension: 'png',
                                                    height: 524,
                                                    width: 1353,
                                                    ratio: 0.38728750923872873,
                                                    type: 'image',
                                                },
                                                mediaFitType: 'contain',
                                                flip: '0',
                                            },
                                        },
                                        id: '4b6f68c4-1ee5-d4ff-0436-8e495be776ac',
                                    },
                                    editor: {
                                        name: 'Text and media',
                                        alias: 'contentPageTextAndMedia',
                                        view: '/App_Plugins/IMPACTDTGE/Views/impactdtge.html',
                                        // @ts-ignore
                                        render: null,
                                        icon: 'icon-pictures-alt-2',
                                        config: {
                                            allowedDocTypes: ['contentPageTextAndMedia'],
                                        },
                                    },
                                    // @ts-ignore
                                    styles: null,
                                    // @ts-ignore
                                    config: null,
                                },
                            ],
                            // @ts-ignore
                            styles: null,
                            // @ts-ignore
                            config: null,
                        },
                    ],
                    // @ts-ignore
                    styles: null,
                    // @ts-ignore
                    config: null,
                },
                {
                    name: '50/50',
                    id: '8d080267-713c-42dc-87b5-0acf8f3f9e25',
                    areas: [
                        {
                            grid: '6',
                            controls: [],
                            // @ts-ignore
                            styles: null,
                            // @ts-ignore
                            config: null,
                        },
                        {
                            grid: '6',
                            controls: [
                                {
                                    value: {
                                        dtgeContentTypeAlias: 'contentPageTitleAndRichText',
                                        value: {
                                            name: 'Icon, title and rich text',
                                            icon: 'chat',
                                            title: 'Kundeservice',
                                            richText:
                                                '<p>Du kan komme i kontakt med vores supportteam på</p>\n<p><a href="tel:+4565654030">+45 65 65 40 30</a> eller <a href="mailto:shop@ftz.dk">shop@ftz.dk</a></p>',
                                            cmsData: {
                                                name: 'Icon, title and rich text',
                                                icon: 'chat',
                                                title: 'Kundeservice',
                                                richText:
                                                    '<p>Du kan komme i kontakt med vores supportteam på</p>\n<p><a href="tel:+4565654030">+45 65 65 40 30</a> eller <a href="mailto:shop@ftz.dk">shop@ftz.dk</a></p>',
                                            },
                                        },
                                        id: '72141e5d-fe6c-04b9-17e0-27ec5c25b585',
                                    },
                                    editor: {
                                        name: 'Icon, title and rich text',
                                        alias: 'contentPageTitleAndRichText',
                                        view: '/App_Plugins/IMPACTDTGE/Views/impactdtge.html',
                                        render: null,
                                        icon: 'icon-bulleted-list',
                                        config: {
                                            allowedDocTypes: ['contentPageTitleAndRichText'],
                                        },
                                    },
                                    styles: null,
                                    config: null,
                                },
                            ],
                            styles: null,
                            config: null,
                        },
                    ],
                    // @ts-ignore
                    styles: null,
                    // @ts-ignore
                    config: null,
                },
            ],
        },
    ],
};

// We rely on the CMS to provide us with content for the 500 page. This means that content should be available at build time
const ErrorPage: NextPageWithLayout<DynamicPageProps> = () => (
    <RootLayoutContainer>
        <RootLayoutContentContainer>
            <GridContainer>
                <ContentPageGrid grid={grid} pageId="500-error" />
            </GridContainer>
        </RootLayoutContentContainer>
    </RootLayoutContainer>
);

export default ErrorPage;
